import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import apiUrl from "../../services/api";
import axios from "axios";
import showNotification from "../../hooks/notificationHook";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import PageLoader from "../../components/PageLoader";

const ValentineForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedOption = location.state?.selectedOption;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    state: "",
    email: "",
    phone: "",
    dedicationName: "",
    gender: "",
    language: "",
    characteristics: [],
    image4video: [],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const characteristics = [
    "Loving",
    "Caring",
    "Understanding",
    "Respectful",
    "Loyal",
    "Affectionate",
    "Honest",
    "Adventurous",
    "Humorous",
    "Ambitious",
    "Kind-hearted",
    "Creative",
    "Intelligent",
    "Trustworthy",
    "Thoughtful",
    "Confident",
    "Generous",
    "Forgiving",
    "Charming",
    "Gentle",
    "Hardworking",
    "Selfless",
    "Warm-hearted",
    "Determined",
    "Cheerful",
  ];

  const [previewImage, setPreviewImage] = useState(null);

  //   const handleImageChange = (e) => {
  //     const file = e.target.files[0];
  //     if (file) {
  //       setFormData((prev) => ({
  //         ...prev,
  //         image4video: file,
  //       }));
  //       const imageUrl = URL.createObjectURL(file);
  //       setPreviewImage(imageUrl);
  //     }
  //   };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    if (selectedOption === 2) {
      // Combine existing and new files
      const combinedFiles = [...formData.image4video, ...files];

      if (combinedFiles.length < 2) {
        showNotification(
          "error",
          "Please upload at least 2 images for Video Song."
        );
        return;
      }
      if (combinedFiles.length > 10) {
        showNotification("error", "You can only upload up to 10 images.");
        return;
      }

      setFormData((prev) => ({
        ...prev,
        image4video: combinedFiles,
      }));

      const combinedImageUrls = combinedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      setPreviewImage(combinedImageUrls);
    } else if (selectedOption === 1) {
      if (files.length > 1) {
        showNotification(
          "error",
          "You can only upload one image for Love Album."
        );
        return;
      }

      setFormData((prev) => ({
        ...prev,
        image4video: files,
      }));

      const imageUrls = files.map((file) => URL.createObjectURL(file));
      setPreviewImage(imageUrls);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...formData.image4video];
    updatedImages.splice(index, 1);

    setFormData((prev) => ({
      ...prev,
      image4video: updatedImages,
    }));

    const updatedPreviewImages = [...previewImage];
    updatedPreviewImages.splice(index, 1);

    setPreviewImage(updatedPreviewImages);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const newCharacteristics = checked
      ? [...formData.characteristics, value]
      : formData.characteristics.filter((char) => char !== value);
    setFormData({ ...formData, characteristics: newCharacteristics });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavigation = (responseData) => {
    const dataToPass = {
      userName: formData.firstName,
      email: formData.email,
      contact: formData.phone,
      responseData: responseData,
    };

    navigate("/valantine-product-checkout", { state: dataToPass });
  };

  const fetchOccasions = async () => {
    const payload = { occasion_name: "valentine" };

    try {
      const response = await axios.post(apiUrl.fetchOccassion, payload);
      const data = response.data;
      const occassionId = data[0].id;
      localStorage.setItem("occassionId", data[0].id);
      return data;
    } catch (error) {
      console.error("Error fetching occasions:", error);
    }
  };

  useEffect(() => {
    fetchOccasions();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validation logic
    const nameRegex = /^[a-zA-Z ]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;

    // Validate each form field
    if (!nameRegex.test(formData.firstName)) {
      showNotification("error", "First name must contain only letters.");
      return;
    }
    if (!nameRegex.test(formData.lastName)) {
      showNotification("error", "Last name must contain only letters.");
      return;
    }
    if (!emailRegex.test(formData.email)) {
      showNotification("error", "Enter a valid email address.");
      return;
    }
    if (!phoneRegex.test(formData.phone)) {
      showNotification("error", "Phone number must be 10 digits.");
      return;
    }
    if (!nameRegex.test(formData.dedicationName)) {
      showNotification("error", "Dedication name must contain only letters.");
      return;
    }

    if (formData.gender === "") {
      showNotification("error", "Please select singer gender.");
      return;
    }

    if (formData.language === "") {
      showNotification("error", "Please select language.");
      return;
    }

    if (formData.characteristics.length < 4) {
      showNotification(
        "error",
        `Please select at least 3 characteristics. You have selected ${formData.characteristics.length}.`
      );
      return;
    }

    // if (formData.image4video === null) {
    //   showNotification("error", "Please select the image.");
    //   return;
    // }
    if (selectedOption === 2 && formData.image4video.length < 2) {
      showNotification(
        "error",
        "Please upload at least 2 images for Video Song."
      );
      return;
    }

    if (selectedOption === 1 && formData.image4video.length > 1) {
      showNotification(
        "error",
        "You can only upload one image for Love Album."
      );
      return;
    }

    const payload = new FormData();
    payload.append("occasion_id", localStorage.getItem("occassionId"));
    payload.append("first_name", formData.firstName);
    payload.append("last_name", formData.lastName);
    payload.append("mobile", formData.phone);
    payload.append("email", formData.email);
    payload.append("street_address", null);
    payload.append("town", formData.city);
    payload.append("state", null);
    payload.append("region", null);
    payload.append("postcode", null);
    payload.append("dedicated_name", formData.dedicationName);
    payload.append("package_type", selectedOption);
    payload.append("singer_gender_preferance", formData.gender);
    payload.append(
      "person_characteristics",
      JSON.stringify(formData.characteristics)
    );
    payload.append("language", formData.language);
    // if (formData.image4video) {
    //   payload.append("image4video",formData.file || null);
    // }
    // formData.image4video.forEach((file) => {
    //   payload.append("image4video", file);
    // });
    if (formData.image4video && formData.image4video.length > 0) {
      formData.image4video.forEach((file) => {
        payload.append(`image4video`, file);
      });
    } else {
      // Send an empty file if required by the backend
      const emptyFile = new Blob([], { type: "application/octet-stream" });
      payload.append("image4video", emptyFile);
    }

    try {
      // Show loader
      setLoading(true);

      const response = await axios.post(apiUrl.saveOccassionForm, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Hide loader after success
      setLoading(false);

      localStorage.setItem("formNumber", response.data.form_no);
      handleNavigation(response.data);
      showNotification(
        "success",
        "Your song dedication form has been submitted successfully!"
      );
    } catch (error) {
      // Hide loader after error
      setLoading(false);

      console.error("Error submitting the form:", error);
      showNotification("error", "Failed to submit the form. Please try again.");
    }
  };

  const handleCancelClick = () => {
    navigate("/valentines");
  };

  return (
    <>
      <div className="bg-pink-100 min-h-screen flex items-center justify-center p-4">
        <div className="absolute top-10 left-6 md:left-10 animate-ping">❤️</div>
        <div className="absolute top-20 right-6 md:right-16 animate-ping">
          💕
        </div>

        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-3xl border-2">
          <div className="flex items-center space-x-12 mb-2">
            <button
              onClick={handleCancelClick}
              className="text-left text-2xl mr-1 font-bold"
              aria-label="Go back"
            >
              <IoArrowBackCircleOutline size={32} />
            </button>
            <h1 className="text-3xl font-bold text-pink-600  text-center">
              Order Form
            </h1>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-base font-medium text-black mb-1">
                  Your First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="w-full border-gray-200 px-4 border rounded-md  shadow-sm focus:ring-red-500 focus:border-red-500 focus:outline-none"
                  placeholder="Arjun"
                  maxLength={20}
                />
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-1">
                  Your Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="w-full border-gray-200 px-4 border rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 focus:outline-none"
                  placeholder="Mishra"
                  maxLength={20}
                />
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-1">
                  Your City
                </label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className="w-full border-gray-200 px-4 border rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 focus:outline-none"
                  placeholder="Mumbai"
                />
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-1">
                  Your Email
                  <p className="text-sm  text-gray-500">
                    (Please ensure you type the correct email address since the
                    order will be delivered to your email address)
                  </p>
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full border-gray-200 px-4 border rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 focus:outline-none"
                  placeholder="XYZ@gmail.com"
                />
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-1">
                  Your Mobile
                  <p className="text-sm  text-gray-500">
                    (Please ensure the number entered is correct. Please DO NOT
                    add +91. Only submit your 10 digit mobile number)
                  </p>
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="w-full border-gray-200 px-4 border rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 focus:outline-none"
                  placeholder="9810078630"
                />
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-1">
                  First Name of Person for whom the Songs are. The Name of the
                  Person you are making the Songs for (Only first name, no
                  surname)
                  <p className="text-sm  text-gray-500">
                    (Only First Name. No Surname. No Pet Name. Only First Name
                    as in Aadhar Card or Passport or Driving License)
                  </p>
                </label>
                <input
                  type="text"
                  name="dedicationName"
                  value={formData.dedicationName}
                  onChange={handleInputChange}
                  className="w-full border-gray-200 px-4 border rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 focus:outline-none"
                  placeholder="Priya, Sagar, Avani, Aryan"
                  maxLength={20}
                />
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-2">
                  Language
                </label>
                <div className="flex space-x-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="language"
                      value="hindi"
                      checked={formData.language === "hindi"}
                      onChange={handleInputChange}
                      className="mr-2 focus:ring-red-500"
                    />
                    Hindi
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="language"
                      value="english"
                      checked={formData.language === "english"}
                      onChange={handleInputChange}
                      className="mr-2 focus:ring-red-500"
                    />
                    English
                  </label>
                </div>
              </div>

              <div>
                <label className="block text-base font-medium text-black mb-2">
                  Gender of Singer's Voice
                </label>
                <div className="flex space-x-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      checked={formData.gender === "male"}
                      onChange={handleInputChange}
                      className="mr-2 focus:ring-red-500"
                    />
                    Male Voice
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      checked={formData.gender === "female"}
                      onChange={handleInputChange}
                      className="mr-2 focus:ring-red-500"
                    />
                    Female Voice
                  </label>
                </div>
              </div>
            </div>

            <div className="relative" ref={dropdownRef}>
              <label className="block text-base font-medium text-black">
                Characteristics of Person for who the song is (Minimum 3 upto
                10)
                <span className="text-sm text-gray-500">
                  <span className="text-gray-800 font-semibold ml-1">
                    {" "}
                    {formData.characteristics.length}
                  </span>{" "}
                  : Selected
                </span>
              </label>
              <div className="relative mt-2">
                <button
                  type="button"
                  className="w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:border-black sm:text-sm"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {formData.characteristics.length > 0
                    ? formData.characteristics.join(", ")
                    : "Select characteristics"}
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06 0L10 10.94l3.71-3.73a.75.75 0 011.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 010-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </button>

                {dropdownOpen && (
                  <div className="absolute z-10 mt-2 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {characteristics.map((char) => (
                      <label
                        key={char}
                        className="flex items-center px-4 py-2 hover:bg-gray-100"
                      >
                        <input
                          type="checkbox"
                          value={char}
                          checked={formData.characteristics.includes(char)}
                          onChange={handleCheckboxChange}
                          className="mr-2"
                        />
                        {char}
                      </label>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div>
              <label className="block text-base font-medium text-black mb-3">
                Add Image
                <p className="text-sm text-gray-500">
                  (If you add an image, we will deliver a video of any one song
                  with this image, which can be shared on Insta / WhatsApp /
                  other social media platforms. It's best to add a lovely
                  picture of the two of you.)
                </p>
              </label>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
                multiple={selectedOption === 2}
                className="w-full text-gray-700 border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500"
              />
              <div
                className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-6 gap-4 mt-4 overflow-x-auto max-h-40"
                style={{ scrollbarWidth: "thin" }}
              >
                {Array.isArray(previewImage) && previewImage.length > 0 ? (
                  previewImage.map((image, index) => (
                    <div key={index} className="relative group">
                      <img
                        src={image}
                        alt={`Preview ${index + 1}`}
                        className="w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 rounded-md shadow-md object-cover"
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleRemoveImage(index);
                        }}
                        className="absolute top-0 right-0 h-7 w-7 bg-red-500 text-white rounded-full p-1 shadow-md opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        ✕
                      </button>
                    </div>
                  ))
                ) : (
                  <p className="col-span-6 text-center text-gray-500">
                    No images selected
                  </p>
                )}
              </div>
            </div>

            <div className="flex justify-center space-x-4">
              <button
                type="submit"
                className={`w-full bg-gradient-to-r from-red-500 to-pink-500 text-white py-4 md:py-3 px-6 rounded-xl text-base md:text-lg font-semibold shadow-lg ${
                  loading ? "opacity-70 cursor-not-allowed" : "hover:scale-105"
                } hover:shadow-xl transition duration-300 glow-effect`}
                disabled={loading}
              >
                {loading ? "Processing..." : "Make Payment"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ValentineForm;
