import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import successImage from "../../assets/image_icons/success.webp";
// import NotFoundPage from '../../src/reusable/404_page.jsx'

function ValentinePaymentSuccess() {
  const navigate = useNavigate();
  // const location = useLocation();
  // const { state } = location || {};
  // const { status, message, transactionIds } = state || {};

  // const price = state.amount;
  // const currency = state.currency;
  // const transactionId = state.order_id;
  // const itemIds = ['item1', 'item2'];
  // const itemCategory = 'Subscription';
  // const numberItems = 1;
  // const userEmail = state.email;
  // const userPhoneNumber = state.contact;

  // const priceInRupees = price / 100;

  useEffect(() => {
    if (!localStorage.getItem("formNumber")) {
      navigate("/valentines");
    }
  }, [navigate]);

  return (
    <main className="flex justify-center items-center min-h-screen bg-pink-100">
      <section
        data-layername="alert01"
        className="flex pr-2.5 max-w-[600px] small-model-margin"
      >
        <div className="flex flex-col grow shrink-0 justify-center items-center px-10 py-9 bg-gray-100 rounded-xl shadow-lg border border-solid border-gray-200 w-fit max-md:px-5 max-md:max-w-full max-md:m-6">
          <div className="flex flex-col items-center gap-4 max-md:max-w-full">
            <img
              loading="lazy"
              src={successImage}
              alt="Success Icon"
              className="object-contain w-28 max-md:w-20 mt-4 aspect-square animate-bounce"
            />
            <div className="flex flex-col items-center">
              <h2 className="text-3xl text-center font-bold text-gray-800">
                Payment Successful!
              </h2>
              <p className="mt-2 text-lg max-md:text-base text-gray-600 text-center">
                Your Order has been placed and your order ID is <span className="text-black font-semibold">{localStorage.getItem("formNumber") || "-"}</span>
              </p>
              <p className="mt-2 text-center text-xl max-md:text-lg font-bold text-pink-400">
                Enjoy beautiful melodies for your loved ones!
              </p>
              <p className="mt-2 text-lg max-md:text-base text-gray-800 text-center">
                Your Love Album will be delivered to your email or WhatsApp within 24 hours.
              </p>
            </div>
            <button
              className="w-full bg-gradient-to-r from-red-500 to-pink-500 text-white py-3 px-6 rounded-xl text-lg font-semibold hover:from-pink-500 hover:to-red-500 transition duration-300 shadow-lg transform hover:scale-105"
              onClick={() => {
                localStorage.removeItem("occassionId");
                localStorage.removeItem("formNumber");
                navigate("/valentines");
              }}
            >
              Valentines Page
            </button>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ValentinePaymentSuccess;
