import React from "react";
import failureImage from "../../assets/image_icons/remove.webp";

function ValentinePaymentFail() {
  return (
    <main className="bg-pink-100 flex justify-center items-center min-h-screen">
      <section
        data-layername="alert01"
        className="flex pr-2.5 max-w-[600px] small-model-margin"
      >
        <div className="flex flex-col grow shrink-0 justify-center items-center px-10 py-9 bg-gray-100 max-md:m-6 rounded-xl shadow-lg border border-solid border-gray-200 w-fit max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col items-center gap-4 max-md:max-w-full">
            <img
              loading="lazy"
              src={failureImage}
              alt="Failure Icon"
              className="object-contain w-24 max-md:w-18 mt-4 aspect-square animate-shake"
            />
            <div className="flex flex-col items-center">
              <h2 className="text-3xl font-bold text-gray-800">
                Payment Failed!
              </h2>
              <p className="mt-2 text-lg max-md:text-base text-gray-600 text-center">
                Oops... something went wrong. Please try again.
              </p>
              <p className="mt-2 text-xl max-md:text-lg text-center font-bold text-red-600">
                We couldn’t process your payment.
              </p>
            </div>
            <button
              className="mt-6 px-6 py-2 bg-red-400 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-red-600 transition-all duration-300"
              onClick={() => (window.location.href = "/valentines")}
            >
              Retry Payment
            </button>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ValentinePaymentFail;
