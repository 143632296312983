import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
// import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function AboutUs() {
  const [visibleParagraphs, setVisibleParagraphs] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    // Show each paragraph with a 1-second delay
    visibleParagraphs.forEach((_, index) => {
      setTimeout(() => {
        setVisibleParagraphs((prev) => {
          const newVisible = [...prev];
          newVisible[index] = true;
          return newVisible;
        });
      }, index * 800); // Delay each paragraph by 1 second
    });
  }, []);

  const reviewCard = [
    {
      name: "Aarav Joshi",
      designation: "Video Producer",
      review:
        "I used this 'I Made a Song' to create a heartfelt birthday song for my best friend, and it was a huge hit! The personalized tune made the celebration extra special.",
    },
    {
      name: "Devansh Patel",
      designation: "Video Producer",
      review:
        "I Made a Song helped me craft a beautiful anniversary track for my parents. They were so touched by the unique and meaningful melody—it truly made their day unforgettable.",
    },
    {
      name: "Kavya Deshpande",
      designation: "Video Producer",
      review:
        "The slideshow video feature on 'I Made a Song' is simply amazing! It transformed my photos into a beautiful, professionally edited video with a personalized song that matched the mood perfectly.",
    },
    {
      name: "Rohan Mehta",
      designation: "Video Producer",
      review:
        "For my sister’s birthday, I used 'I Made a Song' to create a fun and lively tune. She couldn’t stop smiling—it added such a personal touch to her special day!",
    },
    {
      name: "Ananya Patil",
      designation: "Video Producer",
      review:
        "I made a custom thank-you song for my dad using 'I Made a Song', and it brought tears to his eyes. It’s incredible how easy it was to create something so personal and meaningful.",
    },
    {
      name: "Devansh Patel",
      designation: "Video Producer",
      review:
        "I am absolutely amazed by the Singing Selfie feature on the 'I Made a Song' website! Hearing myself sing a professional-quality track was mind-blowing. It’s easy to use, super fun, and the results are fantastic.",
    },
  ];
  return (
    <>
      {/* </div> */}

      <div className="bg-black min-h-screen px-4 py-12">
        <div className="max-w-6xl mx-auto mt-20 max-md:mt-4">
          <div className="text-center">
            <h1 className="text-4xl md:text-6xl font-bold text-center text-white mb-12 max-md:mb-6 py-2">
              About Us
            </h1>
            <p className="text-xl text-white mb-8 font-bold">
              Discover the story of Digikore Studios Limited and our
              revolutionary approach to AI-driven music creation.
            </p>
          </div>

          <div>
            <p className="text-lg text-white mb-8">
              I Made a Song is owned and operated by Digikore Studios Limited.
              At Digikore Studios, we are redefining the future of music with
              cutting-edge technology and innovation. As pioneers in AI-driven
              music solutions, we are building the world’s first-of-its-kind AI
              music platform, where artistry meets artificial intelligence to
              create transformative musical experiences.
            </p>

            <p className="text-lg text-white mb-8">
              With a foundation in advanced technology, Digikore Studios
              combines deep expertise in artificial intelligence, machine
              learning, and sound engineering to push the boundaries of what
              music can be. Our team of seasoned engineers, musicians, and AI
              experts work collaboratively to design a platform that empowers
              creators, artists, and listeners to explore new realms of musical
              creativity.
            </p>

            <p className="text-lg text-white mb-8">
              We understand the profound impact music has on people, and our
              platform is engineered to make music creation accessible, dynamic
              and endlessly innovative. Whether you are an artist seeking unique
              compositions or a listener craving immersive experiences, Digikore
              Studios is here to elevate your journey with state-of-the-art AI
              technology.
            </p>

            <p className="text-lg text-white mb-8">
              At Digikore, our commitment is not only to stay at the forefront
              of AI in music but to pioneer a new era where technology and
              creativity harmonize seamlessly. Join us as we shape the future of
              sound, setting a new standard in the world of digital music.
            </p>
          </div>
          <div className="text-center mt-14">
            <h1 className="text-2xl md:text-4xl font-bold text-center text-white mb-12 max-md:mb-6 py-2">
              User Reviews on I Made A Song
            </h1>
          </div>
          <div className="gap-4 ">
            <Swiper
              slidesPerView={3}
              navigation={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              loop={true}
              spaceBetween={16}
              modules={[Autoplay]}
              breakpoints={{
                640: {
                  slidesPerView: 3,
                },
                0: {
                  slidesPerView: 1,
                },
              }}
              className="swiper-container"
            >
              {reviewCard.map((review, index) => (
                <SwiperSlide>
                  <div
                    key={index}
                    className={`flex flex-col gap-4 px-4 py-6 w-full border-0 rounded-xl`}
                    style={{
                      background:
                        "linear-gradient(45deg, #144c49, #1f2a38, #1c2129, transparent)",
                      height: "165px",
                    }}
                  >
                    <p className="text-sm text-white">"{review.review}"</p>
                    <p className="text-sm text-white text-end	">
                      -{review.name}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
