import axios from "axios";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import apiUrl from "../../services/api";
import showNotification from "../../hooks/notificationHook";

function ValentineCheckOut() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const data = location.state;
  const userName = data.firstName;
  const userEmail = data.email;
  const userContact = data.phone;
  const packagePrice = data.responseData.package_price;
  const packageType = data.responseData.package_type;
  useEffect(() => {
    const loadRazorpayScript = () => {
      const script = document.createElement("script");
      script.src = apiUrl.razorpayCheckout;
      script.async = true;
      document.body.appendChild(script);
    };
    loadRazorpayScript();
  }, []);

  function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const currentDateTime = getCurrentDateTime();

  const amountInINR = 1;
  // const amountInINR = packagePrice;
  const amountInPaise = amountInINR * 100;
  const storedFormNumber = localStorage.getItem("formNumber");

  if (!storedFormNumber) {
    return null;
  }

  // Function to handle API call
  const handlePayment = async () => {
    // if (
    //   amountInINR !== parseInt(process.env.REACT_APP_LOVE_ALBUM_PRICE) &&
    //   amountInINR !== parseInt(process.env.REACT_APP_SLIDESHOW_VIDEO_PRICE)
    // ) {
    //   showNotification("error", "Something went wrong, please retry payment");
    //   return;
    // }

    setIsLoading(true);
    setMessage("");

    const payload = {
      amount: amountInPaise,
      currency: "INR",
      notes: {
        occasion_form_id: storedFormNumber,
      },
    };

    try {
      await axios
        .post(apiUrl.createOccassionOrder, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (data) => {
          setMessage("Success");
          await handlePaymentSDK(data);
        });
    } catch (error) {
      console.error("API Error:", error);
      setMessage("Something went wrong. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentSDK = async (data) => {
    const orderId = data?.data?.data?.id;

    if (!orderId) {
      setMessage("Invalid response data. Cannot proceed with payment.");
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_LIVE_KEY,
      amount: amountInPaise,
      currency: "INR",
      order_id: orderId,
      name: "Digikore Studio Limited",
      description: "Transaction from I Made a Song - Valentines",
      handler: async function (response) {
        setMessage("Payment successful!");
        await handlePaymentSuccess(response);
      },
      prefill: {
        name: userName,
        email: userEmail,
        contact: userContact,
      },
      theme: {
        color: "#61b1a7",
      },
      modal: {
        ondismiss: async function () {
          const errorPayload = {
            amount: amountInPaise,
            contact: userContact,
            email: userEmail,
            razorpay_order_id: orderId,
            date_time: currentDateTime ?? "2025-11-10 10:00:00",
            level: "2",
            problem: "Error in Razorpay SDK - For Valentines",
          };

          await handleFailure(errorPayload);

          setMessage("Payment was not completed. Please try again.");
        },
      },
    };

    const rzp1 = new window.Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      console.error("Payment Failed:", response);
      setMessage("Payment failed. Please try again.");
    });

    rzp1.open();
  };

  const handlePaymentSuccess = async (response) => {
    const payload = {
      payment_id: response.razorpay_payment_id,
      occasion_no: "1",
      form_no: storedFormNumber,
      razorpay_order_id: response.razorpay_order_id,
      // ip_address: "192.168.1.1",
    };

    try {
      await axios
        .post(apiUrl.occasionPayment, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((paymentData) => {
          console.log(paymentData);
          navigate("/valantine-payment-success");
        });
    } catch (error) {
      // Handle errors
      console.error("Error calling the API:", error);
    }
  };

  const handleFailure = async (errorPayload) => {
    console.error("Payment Error Payload:", errorPayload);

    navigate("/valantine-payment-fail");
  };

  return (
    <div className="h-screen bg-pink-100 flex flex-col justify-center items-center py-8 px-4 relative">
      {/* Heart Decorations */}
      <div className="absolute top-10 left-6 md:left-10 animate-ping">❤️</div>
      <div className="absolute top-20 right-6 md:right-16 animate-ping">💕</div>
      <div className="absolute bottom-16 left-10 animate-ping">💖</div>

      <div className="w-full max-w-lg md:w-6/12 bg-white bg-opacity-90 rounded-3xl shadow-2xl p-6 md:p-8 flex flex-col items-center gap-6">
        {/* Header Section */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1 text-center"
        >
          <h1 className="text-3xl md:text-3xl font-extrabold text-pink-600 leading-snug font-cursive">
            Valentine's Checkout
          </h1>
          <p className="text-base md:text-lg text-gray-600 mt-3">
            Complete your payment process to share your love with a heartfelt
            Valentine’s song!
          </p>
        </motion.div>

        {/* Song Details Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex-1 text-center w-full"
        >
          <div className="bg-pink-100 bg-opacity-80 rounded-lg shadow-md p-4 md:p-6">
            <h2 className="text-xl md:text-2xl font-bold text-pink-600 mb-2">
              Package Details
            </h2>
            <p className="text-sm md:text-base text-gray-700 mb-4">
              {packageType === "1"
                ? "Your chosen Love Album of 10 Songs and 1 Video Song"
                : packageType === "2"
                ? "Your chosen Slideshow Video Song"
                : ""}
            </p>

            <div className="flex justify-between items-center mt-2">
              <h2 className="text-lg md:text-xl font-bold text-pink-600">
                Amount
              </h2>
              <p className="text-gray-700">Rs. {amountInINR}</p>
            </div>

            <div className="flex justify-between items-center mt-2">
              <h2 className="text-lg md:text-xl font-bold text-pink-600">
                Tax
              </h2>
              <p className="text-gray-700">Rs. 0</p>
            </div>

            <div className="flex justify-between items-center mt-2 border-t border-gray-300 pt-3">
              <h2 className="text-lg md:text-xl font-bold text-pink-600">
                Total
              </h2>
              <p className="text-gray-800 text-xl md:text-2xl font-semibold">
                Rs. {amountInINR}
              </p>
            </div>
          </div>
        </motion.div>

        {/* Button Section */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="w-full"
        >
          <button
            type="button"
            onClick={handlePayment}
            disabled={isLoading}
            className={`w-full bg-gradient-to-r from-red-500 to-pink-500 text-white py-4 md:py-3 px-6 rounded-xl text-base md:text-lg font-semibold shadow-lg transform ${
              isLoading ? "opacity-50 cursor-not-allowed" : "hover:scale-105"
            } hover:shadow-xl transition duration-300 glow-effect`}
          >
            {isLoading ? "Processing..." : "Pay Now"}
          </button>
        </motion.div>

        {/* Message Section */}
        {message && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className={`text-center mt-4 ${
              message.includes("successful") ? "text-green-600" : "text-red-600"
            } font-semibold`}
          >
            {message}
          </motion.div>
        )}
      </div>
    </div>
  );
}

export default ValentineCheckOut;
