import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegPlayCircle } from "react-icons/fa";
import { FaHeart } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import fnpImg from "../../assets/valentineImages/83609_Large.jpg";
import Img1 from "../../assets/valentineImages/Album-I1.png";
import Img2 from "../../assets/valentineImages/Album-I2.png";
import Img3 from "../../assets/valentineImages/Album-I3.png";
import Img4 from "../../assets/valentineImages/Album-I4.png";
import heartSideImg from "../../assets/valentineImages/H1.png";
import sideImg1 from "../../assets/valentineImages/M1.png";
import mobileImg from "../../assets/valentineImages/MOBILE.png";
import img5 from "../../assets/valentineImages/Play-the-song-during-a-romantic.png";
import img6 from "../../assets/valentineImages/Play-the-song-on-a-scenic-romantic-long-drive..png";
import img8 from "../../assets/valentineImages/Rectangle-2-copy-4.png";
import secondBanner from "../../assets/valentineImages/SO.png";
import firstBanner from "../../assets/valentineImages/Untitled-1.png";
import img7 from "../../assets/valentineImages/Use-the-song-as-part-of-your-proposal-moment..png";
import tripImg from "../../assets/valentineImages/easemytrip-removebg-preview.png";
import marvelImg from "../../assets/valentineImages/marvelstudios.png";
import netflixImg from "../../assets/valentineImages/netfli.png";
import notification from "../../assets/valentineImages/notification.png";
import primeImg from "../../assets/valentineImages/primevideo.png";
import apiUrl from "../../services/api";

function ValentinePage() {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);


  useEffect(() => {
    const showModal = () => {
      const lastShownTime = localStorage.getItem("modalLastShown");
      const currentTime = new Date().getTime();

      if (!lastShownTime || currentTime - lastShownTime > 60 * 60 * 1000) {
        setIsModalVisible(true);
        localStorage.setItem("modalLastShown", currentTime);
      }
    };

    showModal();

    const interval = setInterval(() => {
      showModal();
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleOrderNow = () => {

    console.log('clicked')
    setIsModalOpen(true); // Open the modal
  }

  const handleSelection = (value) => {
    if (value === 1) {
      console.log("Love Album selected, value:", value);
    } else if (value === 2) {
      console.log("Video Song selected, value:", value);
    }
    setIsModalOpen(false); 
    navigate("/valantine-form", { state: { selectedOption: value } }); 
  };

  return (
    <div
      className="bg-[#fff6f8] min-h-screen overflow-x-hidden"
      style={{ fontFamily: "'Segoe UI Emoji', sans-serif" }}
    >
      <div className="max-w-7xl mx-auto ">
        <div
          className="bg-no-repeat min-h-screen flex flex-col items-center justify-center px-4 sm:px-6 bg-top rounded-b-[50px] md:bg-cover"
          style={{
            backgroundImage: `url(${firstBanner})`,
            backgroundSize: "cover", // Ensures the image covers the container properly
            // backgroundPosition: "center", // Centers the image
          }}
        >
          {/* Header Section */}
          <div className="ml-2 mt-[24rem] sm:ml-4 sm:mt-60 md:mt-[51rem]">
            <h1
              className="text-2xl max-md:text-3xl sm:text-3xl md:text-5xl text-gray-700 font-Centaur text-center"
              style={{ fontFamily: "Centaur" }}
            >
              The Most Romantic Musical Gift for Your Valentine
            </h1>
            <div className="bg-white rounded-xl px-3 py-2 sm:px-5 sm:py-4 mt-8">
              <p className="text-sm sm:text-md md:text-xl text-pink-600 font-serif text-center">
                Introducing The World’s First Personalized
                <p className="font-semibold">Valentine’s Love Song Album!</p>
              </p>
            </div>
          </div>

          {/* Description Section */}
          <div className="text-center mt-14 sm:mt-6 max-w-3xl mb-5 px-4 sm:px-6">
            <p className="text-pink-950 text-sm sm:text-base md:text-lg">
              Forget the traditional flowers and chocolates—surprise your
              partner with a{" "}
              <span className="font-bold">customized 10-song album</span>{" "}
              featuring their name in the lyrics.
            </p>
          </div>
        </div>

        <div className=" md:mt-10">
          <h2
            className="text-center text-base md:text-3xl  text-[#e68aa0]"
            style={{ fontFamily: "Centaur" }}
          >
            Listen to How Some of Our Customers Created
            <p
              className=" text-2xl md:text-4xl text-pink-600 "
              style={{ fontFamily: "Centaur" }}
            >
              Love Song Albums
            </p>
          </h2>

          <div className="relative px-4 mt-2">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-5 relative z-10 p-4 rounded-lg">
              {[
                {
                  img: Img1,
                  text: "Happy valentines Arjun",
                  icon: <FaRegPlayCircle className="fill-white text-2xl" />,
                  link: "/valentines/202401",
                },
                {
                  img: Img2,
                  text: "Happy valentines Priya",
                  icon: <FaRegPlayCircle className="fill-white text-2xl" />,
                  link: "/valentines/202402",
                },
                {
                  img: Img3,
                  text: "Happy valentines Avani",
                  icon: <FaRegPlayCircle className="fill-white text-2xl" />,
                  link: "/valentines/202403",
                },
                {
                  img: Img4,
                  text: "Happy valentines Vihhan",
                  icon: <FaRegPlayCircle className="fill-white text-2xl" />,
                  link: "/valentines/202404",
                },
              ].map((item, index) => (
                <div key={index} className="relative group overflow-hidden ">
                  <img
                    src={item.img}
                    alt={item.text}
                    className="w-full h-auto   group-hover:scale-110"
                  />
                  <button
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black p-2 rounded-full "
                    onClick={() => navigate(item.link)}
                  >
                    {item.icon}
                  </button>
                  <div className="absolute bottom-0 left-0 w-full bg-black/30 text-white  text-base text-center p-1 translate-y-full group-hover:translate-y-0 transition-all duration-500">
                    {item.text}
                  </div>
                </div>
              ))}
            </div>

            <img
              src={heartSideImg}
              alt="Right Side Illustration"
              className="absolute top-1/2 right-0 transform -translate-y-1/2 w-[100px] h-24 z-0 blur-sm md:hidden"
            />
          </div>
        </div>
        <div className="mt-16 max-md:mt-4">
          <div
            className="flex items-center justify-center  md:bg-cover md:bg-center bg-no-repeat relative "
            style={{
              backgroundImage: `url(${secondBanner})`,
              height: "500px",
              objectFit: "cover",
            }}
          >
            <div className=" w-1/2 md:w-2/5  rounded-lg   absolute top-[20px] md:top-[30%] right-0">
              <div className="bg-white px-1 w-full rounded-l-lg">
                <h3
                  className="text-xs  md:text-2xl  text-[#f26480] px-2 py-2"
                  style={{ fontFamily: "Centaur" }}
                >
                  Exclusive Offer:
                  <span className="line-through text-xs md:text-xl">
                    {" "}
                    ₹5,999
                  </span>{" "}
                  ₹1,999 for a<p>10 songs personized love album</p>
                </h3>
              </div>

              <p className="text-white mt-4 text-base md:text-lg font-serif leading-tight">
                Once your order is placed, the link to your album will be
                digitally delivered via email or WhatsApp.
              </p>
              <button
                className="bg-[#eee6bf] text-[#d74267] mt-4  py-1 px-4 rounded-full shadow-lg mb-4 md:text-2xl border border-white"
                style={{ fontFamily: "Centaur" }}
                onClick={handleOrderNow}
              >
                ORDER NOW
              </button>
            </div>
            <div className="absolute mt-14 md:bottom-0 left-1">
              <p className="text-white mt-4 font-12 font-semibold text-left mb-5 font-sans">
                OFFER VALID TILL 5Th FEBRUARY
              </p>
            </div>
          </div>

          <div className="grid grid-cols-3 md:grid-cols-3 gap-3 mt-[-42%] md:mt-10 px-2">
            <div className="text-center ">
              <img
                src={img5}
                alt="Candlelight dinner"
                className="w-full rounded-lg md:h-60 md:bg-contain"
              />
              <p className="mt-4 text-pink-700 text-sm md:text-xl leading-tight">
                Play the song during a romantic candlelight dinner.
              </p>
            </div>

            <div className="text-center ">
              <img
                src={img6}
                alt="Romantic drive"
                className="w-full rounded-lg md:h-60"
              />
              <p className="mt-4 text-pink-700 text-sm md:text-xl leading-tight">
                Play the song on a scenic romantic drive.
              </p>
            </div>

            <div className="text-center">
              <img
                src={img7}
                alt="Proposal moment"
                className="w-full rounded-lg md:h-60 md:bg-contain"
              />
              <p className="mt-4 text-pink-700 text-sm md:text-xl leading-tight">
                Use the song as part of your proposal moment.
              </p>
            </div>
          </div>

          <div className="text-center mt-8">
            <p
              className="bg-[#eee6bf] md:text-xl inline-block py-2 px-6  shadow-md text-pink-700 p-4 font-semibold rounded-full  border border-white"
              style={{ fontFamily: "Centaur" }}
            >
              Get Your Personalized Love Song Album
            </p>
          </div>
        </div>

        <div className="relative px-8">
          <div className="bg-[#f5eee0] rounded-xl shadow-lg p-4 mt-14 max-md:mt-8 relative z-10">
            <h2 className="text-2xl md:text-4xl text-center text-[#d74267] mb-6 font-serif">
              About The Makers
            </h2>
            <div className="text-[#746c46] space-y-4 mb-8 text-lg md:text-xl flex flex-col items-center justify-center">
              <p>
                Digikore Studios is an NSE-listed firm & a renowned name in the
                entertainment industry.
              </p>
              <ul className="space-y-2 text-lg md:text-xl">
                <li>
                  •<span className="font-semibold"> 24 years</span> of
                  experience in the international media sector
                </li>
                <li>
                  •<span className="font-semibold"> 14 years</span> specializing
                  in film and television
                </li>
                <li>
                  • Delivering{" "}
                  <span className="font-semibold">250+ Hollywood films</span>{" "}
                  and TV series
                </li>
              </ul>
            </div>
            <hr className="bg-white h-1 rounded-full my-2 " />
            <p className="text-center text-[#d74267] font-semibold mt-4 md:text-xl">
            we work with globally acclaimed companies

            </p>
            <div className="flex justify-center items-center gap-1 mt-4 w-full px-1">
              <img src={marvelImg} alt="Marvel Studios" className="h-6" />
              <img src={netflixImg} alt="Netflix" className="h-6" />
              <img src={primeImg} alt="Prime Video" className="h-6" />
            </div>
          </div>

          <img
            src={sideImg1}
            alt="Right Side Illustration"
            className="absolute top-5 right-0 transform -translate-y-1/2 w-48 h-auto  z-20"
          />
        </div>
        <div className="mt-9">
          <div className="text-center text-gray-800">
            <h1
              className="text-xl md:text-4xl font-bold mb-4 text-[#df738b]"
              style={{ fontFamily: "Centaur" }}
            >
              Order Your Personalized <br />
              <span className="text-[#d74267] text-3xl ">
                Love Song Album Now!
              </span>
            </h1>
          </div>

          <div className="grid grid-cols-3 gap-3 mb-8 px-4">
            {[
              {
                price: "1,000",
                label: "PRE-LAUNCH OFFER",
                subtext: "( till 30th Jan )",
              },
              {
                price: "1,500",
                label: "REGULAR PRICE",
                subtext: "( Form Jan 31st to Feb 10th )",
              },
              { price: "2,000", label: "AFTER FEB 10TH" },
            ].map((pkg, index) => (
              <div
                key={index}
                className="h-[140px] flex flex-col justify-center items-center rounded-xl px-0  text-center bg-[#f1c6d1] shadow-lg text-[#d74368]"
              >
                <p className="font-bold mb-2 text-sm md:text-xl">
                  {pkg.label}:
                </p>
                <p className="font-bold mb-2 text-lg md:text-2xl">
                  ₹{pkg.price}
                </p>
                <p className="font-12 md:text-md">{pkg.subtext}</p>
              </div>
            ))}
          </div>

          <div className="grid grid-cols-6 gap-2 items-center text-center mb-14">
            <div className="col-span-1">
              <img src={sideImg1} alt="LeftImage" className="w-full h-auto " />
            </div>

            <div className="col-span-4">
              <button
                className="bg-[#eee6bf] text-[#d74267] text-lg md:text-xl py-1  px-4 rounded-full shadow-lg hover:shadow-xl transition-shadow border border-white"
                style={{ fontFamily: "Centaur" }}
                onClick={handleOrderNow}
              >
                ORDER NOW
              </button>
              <p
                className="text-[#e27d94] font-semibold mt-4 text-xl md:text-4xl"
                style={{ fontFamily: "Centaur" }}
              >
                Special Offer: use code LOVE50
              </p>
              <p className="text-pink-600 mt-2 text-sm font-Open Sans md:text-xl">
                OFFER VALID TILL 5Th FEBRUARY{" "}
              </p>
            </div>

            <div className="col-span-1">
              <img src={sideImg1} alt="RightImage" className="w-full h-auto " />
            </div>
          </div>
        </div>
        <div className="">
          <div className="grid grid-cols-6  items-center">
            <div className="col-span-4 text-center md:text-left text-gray-800 px-4">
              <h1
                className="text-lg md:text-4xl  mb-4 text-[#a18a27]"
                style={{ fontFamily: "Centaur" }}
              >
                Surprise Your Valentine with <br />
                <span className="font-bold">
                  a Personalized Love Song Album
                </span>
              </h1>
              <ul className="list-inside text-[#c98484] mb-4 text-sm md:text-xl">
                <li className="mb-2 flex">
                  <FaHeart className="fill-[#9f0736] mx-1 " /> Digital delivery
                  via email or WhatsApp.
                </li>
                <li className="mb-2 flex">
                  <FaHeart className="fill-[#9f0736] mx-1" /> Features your
                  partner's name.
                </li>
                <li className="flex mb-2">
                  <FaHeart className="fill-[#9f0736] mx-1" /> Celebrate your
                  relationship.
                </li>
              </ul>
              <button
                className="bg-[#eee6bf] text-[#d74267]  py-1 px-6 rounded-full shadow-lg mb-4 md:text-2xl border border-white"
                style={{ fontFamily: "Centaur" }}
                onClick={handleOrderNow}
              >
                ORDER NOW
              </button>
              <p
                className="text-md text-pink-700 md:text-2xl "
                style={{ fontFamily: "Centaur" }}
              >
                Special Offer: use code{" "}
                <span
                  className="font-bold text-pink-700 md:text-2xl"
                  style={{ fontFamily: "Centaur" }}
                >
                  LOVE50
                </span>
              </p>
              <p className="text-sm text-pink-500 md:text-lg">
                OFFER VALID TILL 5Th FEBRUARY
              </p>
            </div>
            <div className="col-span-2 flex justify-center ">
              <img
                src={mobileImg}
                alt="Phone Mockup"
                className=" w-64 h-64 md:w-72 md:h-[28rem]"
              />
            </div>
          </div>
        </div>

        <div className="p-4 mt-4">
          <div className="grid grid-cols-1 md:grid-cols-6 gap-7 items-center">
            <div className="col-span-2 max-md:col-span-4 flex justify-center">
              <img
                src={img8}
                alt="Bonus Offer"
                className="rounded-lg shadow-lg  bg-contain"
              />
            </div>
            <div className="col-span-4 text-left text-gray-800 px-2">
              <h2
                className="text-xl md:text-4xl font-bold mb-3 text-[#a18a27] px-2 text-center"
                style={{ fontFamily: "Centaur" }}
              >
                Lucky You! We have a{" "}
                <span className="text-[#a18a27] text-2xl">BONUS OFFER</span> for
                you!
              </h2>
              <p className="text-[#c98484] mb-3 text-sm md:text-xl ">
                We’re making your moments extra special with <br />
                <span className="">PERSONALIZED IMAGE VIDEO SONG.</span>
              </p>
              <p className="text-[#c98484] mb-3 text-sm md:text-xl ">
                Upload a picture with your partner, and we’ll create an image
                video featuring one of your personalized songs!
              </p>
              <p className="text-[#c98484] text-sm md:text-xl">
                Hurry!
                <br /> Limited Time Only! Offer valid till 5th Feb!
              </p>
            </div>
          </div>
        </div>
        <div className=" ">
          <div className="bg-[#f68690] text-center py-6 ">
            <h2
              className="text-2xl md:text-4xl  text-white px-2"
              style={{ fontFamily: "Centaur" }}
            >
              To Love, Laughter & Happily Ever Afters!
            </h2>
            <p className="text-white mt-4 px-2 md:text-xl">
              Imagine the look on their face when they hear their name in a
              beautiful melody. It’s more than just a GIFT—it’s a deep,
              emotional connection.
            </p>
            <p className="text-white italic md:text-xl mt-4">
              Don’t Wait—Limited Time Offer!
            </p>
          </div>

          <div className="text-center mt-4">
            <div className="bg-[#eee6bf] md:text-xl inline-block py-1 px-6  shadow-md text-pink-700 p-4 font-semibold rounded-full  border border-white">
              <p
                className="text-[#d74267] font-bold md:text-2xl"
                style={{ fontFamily: "Centaur" }}
              >
                Special Offer: use code LOVE50
              </p>
            </div>
            <p className="text-[#dd6480] text-sm mt-4 font-Open Sans md:text-xl">
              OFFER VALID TILL 5TH FEBRUARY
            </p>
          </div>

          <div className="text-center mt-4 ">
            <button
              className="bg-[#f68690] w-full text-white py-3 px-12  hover:bg-pink-700 transition md:text-2xl border "
              style={{ fontFamily: "Centaur" }}
              onClick={handleOrderNow}
            >
              ORDER NOW
            </button>
          </div>

          <div className="mt-6 flex justify-end items-center px-3">
            <div className="flex gap-4 items-center">
              <p
                className="text-pink-500 text-sm  text-right"
                style={{ fontFamily: "Centaur" }}
              >
                BRAND PARTNERS
              </p>
              <img src={fnpImg} alt="FNP Logo" className="h-10" />
              <img src={tripImg} alt="EaseMyTrip Logo" className="h-12 " />
            </div>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative  rounded-lg p-0 shadow-lg max-w-sm w-full">
            {/* Close Icon */}
            <button
              onClick={closeModal}
              className="absolute top-4 right-5 font-bold text-4xl text-black"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Image */}
            <div className="flex items-center justify-center">
              <img
                src={notification} // Replace with your image URL
                alt="Modal Content"
                className="rounded-lg w-full"
              />
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-pink-100 bg-opacity-90">
          <div className="relative bg-white w-96 rounded-lg shadow-lg p-6 overflow-hidden z-10">
            {/* Heart decorations */}
            <div className="absolute -top-16 -left-16 w-40 h-40 bg-pink-400 rounded-full transform rotate-45 z-0 opacity-60"></div>
            <div className="absolute -bottom-16 -right-16 w-48 h-48 bg-pink-500 rounded-full transform rotate-45 z-0 opacity-60"></div>

            {/* Modal Content */}
            <div className="relative z-10">
              <h2 className="text-2xl font-bold text-white mb-4 text-center">
                💖 Make Your Choice 💖
              </h2>
              <p className="text-white text-center text-base font-bold mb-6">
                Celebrate this Valentine’s Day by selecting either a Love Album or a Video Song.
              </p>
              <div className="flex justify-between space-x-4">
                <button
                  onClick={() => handleSelection(1)}
                  className="flex-1 px-4 py-2 bg-pink-500 text-white font-medium rounded-md hover:bg-red-600 transition shadow-md"
                >
                  Love Album
                </button>
                <button
                  onClick={() => handleSelection(2)}
                  className="flex-1 px-4 py-2 bg-cyan-400 text-white font-medium rounded-md hover:bg-blue-500 transition shadow-md"
                >
                  Video Song
                </button>
              </div>
              <button
                onClick={() => setIsModalOpen(false)}
                className="mt-6 text-sm text-gray-300 underline hover:text-pink-500 transition block mx-auto"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}


    </div>

  );
}

export default ValentinePage;
